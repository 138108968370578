import React from "react"
import Layout from "../components/Layout"

const NotFound = () => (
  <Layout>
    <div>
      <h1>Oops, that's a 404</h1>
    </div>
  </Layout>
)

export default NotFound;
